export default {
  "test": {
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["salut ", _interpolate(_named("input")), " !!"])},
    "tv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intitati tv"])}
  },
  "onboarding": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])},
    "endHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "startBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn"])},
    "footerBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 1."])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen im Kreismuseum!"])},
    "1text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Anwendung wurde entwickelnt, um das Museumbesuch-Erlebnis zu ergänzen. Durch die Anwendung können zusätzliche Informationen mit den Austellungen in der ausgewählten Sprache erhalten werden, aber wird auch aine Zeitlinie erstellt, eine Chronologie des Museumbesuchs, die später noch einmal bewacht oder geteilt werden kann."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 2."])},
    "2text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nützen Sie diesen Code, um die Spracheeinstellungen zu erhalten und die besuchten Exponaten anzuzeigen und zu speichern. Der Code muss nicht gespechert werden, ist sichtbar während des Besuchs."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 3."])},
    "3title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nützung der QR Codes"])},
    "3text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige der in den Ausstellungen des Mueums präsentierten Exponaten und Themen sind mit QR-Codes gekennzeichnet. Durch dieser Codes könne die Besucher zusätzliche Informationen bekommen. Um eine Code zu scannen, drücken Sie die Scan-Taste am uneteren Bildschirmrand."])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 4."])},
    "4title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Erinnerung aus dem Museum!"])},
    "4text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Museum haben wir oft keine Zeit, alle Informationen durchzulesen, oder interessiert uns im Moment nicht. Später erinnern wir uns, aber ist es zu spät. Durch underer Anwendung bieten wir eine Möglichkeit um alle Exponaten und Themen in einen eigenen Zeitliste zu sammeln,leicht erreichbar, die können Sie später studieren oder teilen."])},
    "endMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzliche Glückwunsche!"])},
    "endTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präesentation beende!"])},
    "endText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präesentation beendet, aber wenn Sie Frage Haben, oder sind Sie blockiert, können Sie die Informationen unter Navigation erneuen."])},
    "finishBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss"])}
  },
  "login": {
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN Code"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
    "registerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Erstellung eines Nutzerprofils benötigen wir die folgende Daten."])},
    "policyText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken des Registrierens stimmen Sie der Speicherung und Verarbeitung ihrer Prsonendaten. Über Datenmanagement können Sie mehr erfahren "])},
    "cookiePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel für die verwendung personelle Daten."])},
    "cookiePolicyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsrichtlinie der Cookies"])},
    "cookiePolicyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Unsere Website verwendet Cookies, um ihren Besuchern ein besseres Surferlebnis und Dienstleistungen zu bieten, die auf die Interessen jedes Besuchers zugeschnitten sind. Durch die Nutzung dieser Website stimmen Sie der Verwendung von Cookies gemäß dieser Richtlinie zu deren Verwendung zu.</p><p><strong>Was sind Cookies?</strong></p><p>Cookies sind kleine Dateien, bestehend aus Buchstaben und Zahlen, die auf einem Computer, Mobiltelefon oder anderen Gerät gespeichert werden, von dem aus auf das Internet zugegriffen wird. Cookies werden auf Anfrage eines Webservers, eines Browsers (zB Internet Explorer, Chrome, Mozilla Firefox) installiert. Cookies sind vollständig „passiv“, enthalten keine Software, Viren oder Spyware und können nicht auf die Informationen auf der Festplatte des Nutzers zugreifen. Cookies selbst benötigen keine personenbezogenen Daten. Sie können uns beispielsweise mitteilen, ob Sie unsere Seite schon einmal besucht haben oder ein neuer Besucher sind.</p><p><strong>Warum verwenden wir Cookies?</strong></p><p>Beim Besuch der Website können Cookies platziert werden auf:</p><ul><li>Steigerung der Leistung der Website</li><li>Eine Besucheranalyse</li><li>Geolokation zur Registrierung, Protokollierung</li><li>Benutzerregistrierung</li></ul><p><strong>Cookies zur Besucheranalyse</strong></p>"])},
    "acceptCP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annehmen"])},
    "declineCP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnung"])},
    "policyText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mai Adresse"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN/Passwort"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständiger Name"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Herzlich Willkommen, ", _interpolate(_named("input")), "!"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Registrierung!"])},
    "successText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie mehr über die Verwendung der Anwendung erfahren möchten, bitte folgen Sie unserem kurzen Tutorial. Wenn Sie nicht brauchen, wählen Sie die Option: Überspringen"])},
    "skipBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])},
    "startBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorialansicht"])},
    "forgotPasswordText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola nouă a fost trimisă către adresa Dvs. de mail! Vă rugăm, schimbați această parolă!"])},
    "forgotPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am uitat parola"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie das neue Passwort"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortveränderung"])},
    "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke Schön!"])},
    "tabletSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Registrierung!"])},
    "tabletOnBoardingBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter nach Onboarding"])},
    "tabletNewRegisterBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Registrierung"])},
    "bademailorpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Adresse oder Passwort falsch"])},
    "bademail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche E-Mail Adresse"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden Sie das neuen Passwort durch E-Mail"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschaltung"])},
    "logouterror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Abmelden ist ein Fehler aufgetreten! Bitt versuschen Sie es erneuert!"])},
    "quickLoginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche E-Mail Adresse oder leerer Benutzername!"])},
    "passwordHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a vă autentifica, vă rugăm să introduceți codul PIN, dacă nu l-ați schimbat cu o parolă."])}
  },
  "password": {
    "unequal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Passwortfelder sind nicht gleich!"])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das neue Passwort muss mindestens 8 Zeichen lang sein!"])},
    "oldincorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Alte Passwort ist falsch!"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzt erfolgreich!"])}
  },
  "menu": {
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Herzlich Willkommen, ", _interpolate(_named("input")), "!"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Einstellungen"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache verwenden"])},
    "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial Ansicht (Benutzerhandbuch der Anwendung)"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü"])},
    "qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannen"])},
    "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Zeitliste"])},
    "arScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannen AR"])},
    "timelines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Timeline"])},
    "menuQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannen QR Codes"])},
    "selfieGallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie galéria"])},
    "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie készítés"])}
  },
  "qrScan": {
    "openBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
    "foundText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiectul identificat: #"])},
    "backBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück nach Scann"])},
    "badEntityError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad entity id!"])}
  },
  "language": {
    "selectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Sprache"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Inhalt wird in gewählter Sprache angezeicht"])},
    "saveBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
  },
  "entity": {
    "moreBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter nach der Beschreibung"])},
    "backBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scann die nächste Exponate"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
    "toListBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Liste"])},
    "saveBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige"])},
    "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark mode"])},
    "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light mode"])},
    "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Story"])},
    "startGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatek inditasa"])}
  },
  "timeline": {
    "finishInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["După finalizare, timeline-ul nu se editează."])},
    "finishBtnModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés végleges befejezése"])},
    "finishBtnModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A véglegesítés után az idővonal nem szerkeszthető, csak megosztani lehetséges. Ez a művelet nem visszavonható."])},
    "noDeleteBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "timelineTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN eingeben"])},
    "timelineSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Code kann in der Menüanwendung eingesehen werden."])},
    "backBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "saveBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "successTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gespeichert für ", _interpolate(_named("input"))])},
    "emptyTimeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leere Zeitliste"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Zeitliste"])},
    "editBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berabeiten der Zeitliste"])},
    "deleteBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markierte Elemente löschen"])},
    "deleteSimple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitung"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Pin Code oder Benutzer nicht findbar!"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt erfolgreich zur Zeitliste hinzugefügt!"])},
    "warningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt schon zur Zeitliste hinzugefügt!"])},
    "selectedElements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ausgewählte Elemente"])},
    "pinNotInDaily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin isn't in daily pins!"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizitat"])},
    "actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual timeline"])},
    "backMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To Main Menu"])},
    "finishedBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizare timeline-ul"])},
    "finishError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizare nereusită"])},
    "pinCheckTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți codul PIN"])},
    "pinCheckSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a salva timeline-ul Dumneavoastră, vă rugăm tastați codul PIN!"])},
    "pinCheckBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin verificare"])},
    "pinCheckErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cod PIN invalid!"])},
    "warningFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline-ul Dvs. a fost finalizat! Nu este posibil să adăugați un articol nou după finalizarea timeline-ului."])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschide enitatea"])},
    "authorName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Timeline-ul ", _interpolate(_named("input"))])},
    "entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entități"])},
    "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfies"])},
    "selfieFullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizare pe toate"])}
  },
  "400pages": {
    "notFoundText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina căutată nu este disponibilă. Ne cerem scuze."])},
    "notFoundRedirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi la meniul principal."])},
    "accessDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conținutul accesat este disponibil doar din incinta Muzeului Județean Satu Mare. Pentru a vizita conținutul, vă rugăm să vizitați Muzeul!"])}
  },
  "share": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O amintire din Muzeu!"])},
    "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["muzeu"])}
  },
  "selfieInstruction": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kihagyom"])},
    "endHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])},
    "startBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdjük"])},
    "footerBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tovább"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Lépés"])},
    "1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valassza ki a kivant filtert"])},
    "1text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Lépés"])},
    "2title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keszitse el a kepet"])},
    "2text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fenti vizualis segedlet kell tartalmazza, hogy hogyan kell kepet csinalni, az idosebbek kedveert. Itt meg kell emliteni a Galeria reszt is. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Lépés"])},
    "3title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse le vagy ossza meg a képet!"])},
    "3text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."])},
    "endMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideje elkezdeni!"])},
    "endTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végzett a használati utasítással!"])},
    "endText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A prezentáció ezennel bezárult, ha elakad, vagy valamivel nincs tisztában, az effektek közt talál egy ", _interpolate(_named("valamilyen")), " ikont, arra kattintva újra tekintheti a használati utasítást."])},
    "finishBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdjük"])},
    "photoShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megosztás"])},
    "photoSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés az idővonalamra"])},
    "photoDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letöltés"])},
    "photoDeletTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan elveti?"])},
    "photoDeletDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha elveti a kép törlődik."])},
    "photoDeletSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igen, kép törlése"])},
    "photoDownloadSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kép letöltése"])},
    "photoBackToPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a képre"])},
    "photoShareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megosztás"])},
    "photoShareDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociális médiában való megosztás, vagy email küldés"])},
    "photoShareBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])},
    "photoEmailContent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Üdvözöljük, \nitt a kep ", _interpolate(_named("input")), " , hasznald!"])},
    "timelineSaveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare cu succes la timeline"])},
    "downloadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descărcare cu succes"])}
  },
  "scratch": {
    "btnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel itreraktiv"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilépés"])},
    "gameText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duis tellus velit risus sit quisque donec. Semper ante egestas sit pulvinar ut."])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kovetkezo kep"])},
    "startGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatek kezdese"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scratch"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sterge praful de pe obiecte de artă sau peisaje din trecut și descoperă cum arată astăzi prin răzuirea ecranului sau prin sliderului before-after."])},
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasul 1."])},
    "step1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răzuiește imaginea"])},
    "step1Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răzuiește tactil  imaginea afișată"])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasul 2."])},
    "step2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compară"])},
    "step2Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flosește sliderul din dreapta jos și descoperă diferențele și asemănările."])}
  },
  "sound": {
    "nextGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uj feladvany"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probabil ai trecut pe la panoul păsărilor și le-ai ascultat glasul. le poți identifica oare?"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
    "gratulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glückwunsche!"])},
    "gratulationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitae vitae iaculis egestas tortor nunc, ac eget."])},
    "startDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifică glasul păsărilor"])},
    "backText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur startseite"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vogelgeräusch"])},
    "tutorialTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hören"])},
    "tutorialText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE Apasă butonul play și ascultă cu atenție glasul redat."])},
    "tutorialTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifizieren"])},
    "tutorialText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE Atinge pasărea la care te gândești pe baza glasului, și mută aceasta în bulina goală. În cazul în care ai ghicit corect, aceasta se va înverzi și poți continua cu următorul glas. Atenție: poți ghici pentru un glas doar după ce l-ai ascultat."])}
  }
}