import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import axios from 'axios';
import VueAxios from 'vue-axios';
import i18n from './i18n'
import VueSocialSharing from 'vue-social-sharing';
import {createHead} from '@vueuse/head'

createApp(App).use(i18n).use(store).use(router).use(VueAxios, axios).use(VueSocialSharing).use(createHead()).mount("#app");