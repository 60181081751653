import { createStore } from "vuex";
import axios from 'axios';

export default createStore({
  state: {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    user: localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null,
    user_pin: localStorage.getItem('user_pin') ? localStorage.getItem('user_pin') : null,
    name: localStorage.getItem('name') ? localStorage.getItem('name') : null,
    error: false,
    language: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    theme: localStorage.getItem('theme') ? localStorage.getItem('theme') : 'dark',
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
      state.user = JSON.parse(window.atob(token.split('.')[1]))
      localStorage.setItem('token', token)
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_USER_PERMISSIONS (state, payload) {
      state.user.permissions = payload
    },
  
    LOGIN_FAILURE (state) {
      state.token = null;
      localStorage.removeItem('token');
      state.user = null;
      localStorage.removeItem('user');
      state.name = null;
      localStorage.removeItem('name');
      state.user_pin = null;
      localStorage.removeItem('user_pin');
    },
  
    LOGOUT (state) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('user_pin');
      axios.defaults.headers.common = {};
      state.token = null;
      state.user = null;
      state.user_pin = null;
    },
    SET_PIN(state, user_pin) {
      state.user_pin = user_pin;
      localStorage.setItem('user_pin', user_pin);
    },
    SET_NAME(state, name) {
      state.name = name;
      localStorage.setItem('name', name);
    },
    SET_THEME(state, theme) {
      state.theme = theme;
      localStorage.setItem('theme', theme);
    },
    PIN_FAILURE (state) {
      state.user_pin = null
      localStorage.removeItem('user_pin')
    },
  },
  actions: {
    themeSet({commit}, payload) {
      commit('SET_THEME', payload) 
    },
    login({ commit }, payload){
      return new Promise( (resolve, reject) => {
        try {
          const baseURL = process.env.VUE_APP_AXIOS_URL;
          axios.post(baseURL + `/wp-json/jwt-auth/v1/token`, {username: payload.username, password: payload.password}, {})
          .then(response => {
            commit('SET_TOKEN', response.data.token);
            commit('SET_NAME', payload.name);
            resolve(response);
          }).catch( (error) => {
            console.log(error);
            commit('LOGIN_FAILURE');
            resolve({error: 'Bad email or password'});
          });
        }catch(e){
          commit('LOGIN_FAILURE');
          reject(e);
        }
      });
    },
    nameSet({commit}) {
      return new Promise( (resolve, reject) => {
        try {
          const baseURL = process.env.VUE_APP_AXIOS_URL;
          const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null;
          axios.get(baseURL + `/wp-json/wp/v2/name`, {params: {user_id: authUser.data.user.id}}, {})
          .then(response => {
            commit('SET_NAME', response.data.user_name);
            resolve(response);
          }).catch( (error) => {
            console.log(error);
            resolve({error: 'Something went wrong!'});
          });
        }catch(e){
          commit('LOGIN_FAILURE');
          reject(e);
        }
      });
    },
    pinCheck({ commit }){
      return new Promise( (resolve, reject) => {
        try {
          const baseURL = process.env.VUE_APP_AXIOS_URL;
          const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null;
          axios.get(baseURL + `/wp-json/wp/v2/pin`, {params: {user_id: authUser.data.user.id}}, {})
          .then(response => {
            const response_data = response.data;
            if (response_data && response_data != '') {
              commit('SET_PIN', response_data);
            } else {
              commit('PIN_FAILURE');
            }
            resolve(response_data);

          }).catch( (error) => {
            console.log(error);
            commit('PIN_FAILURE');
          });
        }catch(e){
          commit('PIN_FAILURE');
          reject(e);
        }
      })
    },
    timelineCheck({}, entity_id){
      return new Promise( (resolve, reject) => {
        try {
          const baseURL = process.env.VUE_APP_AXIOS_URL;
          const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null;
          axios.get(baseURL + `/wp-json/wp/v2/timeline/entity-check`, {params: {user_id: authUser.data.user.id, entity_id: entity_id}}, {})
          .then(response => {
            resolve(response.data);
          }).catch( (error) => {
            console.log(error);
          });
        }catch(e){
          reject(e);
        }
      })
    },
    publishedChec({}, entity_id){
      return new Promise( (resolve, reject) => {
        try {
          const baseURL = process.env.VUE_APP_AXIOS_URL;
          axios.get(baseURL + `/wp-json/wp/v2/entity/notfound-check`, {params: {entity_id: entity_id}}, {})
          .then(response => {
            resolve(response.data);
          }).catch( (error) => {
            console.log(error);
          });
        }catch(e){
          reject(e);
        }
      })
    },
    logOut({commit}) {
      return new Promise( (resolve, reject) => {
        try {
          commit('LOGOUT');
          resolve('success');
        } catch(e){
          reject(e);
        }
      });
    },
  },
  modules: {},
});
